<div class="container-fluid">
  <div *ngIf="configService.showSignUpModal && !userSessionService.isLoggedIn" class="fix-to-top d-none" #signUpModal>
    <div class="card text-white bg-primary">
      <div class="card-header">
        <h5 class="card-title mb-0">Sign up &mdash; free for members</h5>
      </div>
      <div class="card-body text-dark bg-light">
        <div class="row">
          <div class="col-sm-4 text-center">
            <img src="/assets/img/sign-up.svg" alt="" class="pt-sm-3 pl-sm-2">
          </div>
          <div class="col-sm-8">
            <p class="card-text">With an account, you can easily <strong>save your favorite vehicles and searches</strong> so you can pick up where you left off when you come back later.</p>
            <p class="card-text">Signing up only takes a few seconds.</p>
          </div>
        </div>
      </div>
      <div class="card-footer bg-light">
        <p class="card-text"><button type="button" class="btn btn-success mr-2" [routerLink]="['/createAccount']">Sign Up</button><button type="button" class="btn btn-link mr-2" [routerLink]="['/login']">Login</button><button type="button" class="btn btn-warning float-right" (click)="hideSignUpModal()">Not Now</button></p>
      </div>
    </div>
  </div>

  <div class="l2-search-viewsort">
    <div class="l2-search-label l2-search-viewsort-cols">
      <div class="l2-searchbar-icon">
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="#fff">
          <path d="M80-200v-60h400v60H80Zm0-210v-60h200v60H80Zm0-210v-60h200v60H80Zm758 420L678-360q-26 20-56 30t-62 10q-83 0-141.5-58.5T360-520q0-83 58.5-141.5T560-720q83 0 141.5 58.5T760-520q0 32-10 62t-30 56l160 160-42 42ZM559.765-380Q618-380 659-420.765q41-40.764 41-99Q700-578 659.235-619q-40.764-41-99-41Q502-660 461-619.235q-41 40.764-41 99Q420-462 460.765-421q40.764 41 99 41Z"/>
        </svg>
      </div>
      <div class="l2-searchbar-text">SEARCH</div>
    </div>
    <div class="l2-view-cols l2-search-viewsort-cols">
      <div class="l2-view-cols-icon" (click)="updateViewCols(1)">
        <img [src]="viewCols === 1 ? '/assets/img/view-1col-black.png' : '/assets/img/view-1col.png'">
      </div>
      <div class="l2-view-cols-icon" (click)="updateViewCols(2)">
        <img [src]="viewCols === 2 ? '/assets/img/view-2cols-black.png' : '/assets/img/view-2cols.png'">
      </div>
      <div class="l2-view-cols-icon" (click)="updateViewCols(3)">
        <img [src]="viewCols === 3 ? '/assets/img/view-3cols-black.png' : '/assets/img/view-3cols.png'">
      </div>
    </div>    
    <div class="l2-sorting l2-search-viewsort-cols">
      <!-- <select id="sortByInput" [(ngModel)]="selectedSortOption" formControlName="sortListSearch">
        <option value="Default" selected>Default Sorting</option>
        <option value="AscendingPrice">Sort by Price Ascending</option>
        <option value="DecendingPrice">Sort by Price Ascending</option>
      </select> -->
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 pl-md-0">
      <app-search-form (newSearch)="loadResults($event)"></app-search-form>
    </div>

    <div class="col">
      <ng-container [ngSwitch]="searchStatus">
        <div *ngSwitchCase="SEARCH_INIT">
          <div class="card">
            <div class="card-body">
              <div class="d-sm-flex justify-content-between align-items-center">
                <p class="lead mb-sm-0"><span *ngIf="userSessionService.firstName">Hello, {{userSessionService.firstName}}. </span>Please use the search form to begin your search for your next vehicle.</p>
                <app-account-btns (loggedOut)="userIsLoggingOut()"></app-account-btns>
              </div>
            </div>
          </div>

          <div class="mt-3"><app-favorite-vehicles-list></app-favorite-vehicles-list></div>

          <p *ngIf="(configService.loanAppUrl$ | async)?.length" class="alert alert-light mt-3">Are you pre-approved for your loan? If not, <a target="_blank" [href]="configService.loanAppUrl$ | async" (click)="userSessionService.recordLoanAppAccess()">get pre-approved now</a> and find out how much you're qualified to borrow. <ng-container *ngIf="configService.lowestRate >= 0">Rates start as low as {{configService.lowestRate}}%.</ng-container></p>
        </div>

        <div *ngSwitchCase="SEARCH_ACTIVE">
          <div class="card">
            <div class="card-body">
              <p class="lead mb-0">Please wait while we find vehicles matching your search.</p>
            </div>
          </div>
          <p class="m-5 text-center"><img src="/assets/img/searching.gif" alt="Searching..."></p>
        </div>

        <ng-container *ngSwitchDefault>
          <div *ngIf="listingCount > 0" class="main-content">
            <div class="card">
              <div class="card-body">
                <div class="d-sm-flex justify-content-between align-items-center">
                  <!-- <div class="l2-results-num l2-flex">
                    <p class="lead">Show Results by:</p>
                    <select id="showResultsBy" formControlName="showResultsBySearch" (change)="updateListingCount($event)">
                      <option [value]="10" [selected]="showResultsByNumber === 10">10</option>
                      <option [value]="12" [selected]="showResultsByNumber === 12">12</option>
                      <option [value]="15" [selected]="showResultsByNumber === 15">15</option>
                      <option [value]="20" [selected]="showResultsByNumber === 20">20</option>
                      <option [value]="24" [selected]="showResultsByNumber === 24">24</option>
                      <option [value]="25" [selected]="showResultsByNumber === 25">25</option>
                      <option [value]="30" [selected]="showResultsByNumber === 30">30</option>
                      <option [value]="40" [selected]="showResultsByNumber === 40">40</option>
                      <option [value]="50" [selected]="showResultsByNumber === 50">50</option>
                    </select>
                  </div> -->
                  <p class="lead mb-2 m-sm-0">
                    Hi<span *ngIf="userSessionService.firstName">, {{userSessionService.firstName}}</span>!

                    Here <span *ngIf="listingCount > 1">are <span *ngIf="listingCount < configService.resultsPerPage">the </span> <strong>{{listingCount}}</strong></span><span *ngIf="listingCount === 1">is the only</span> vehicle<span *ngIf="listingCount > 1">s</span> matching your <span *ngIf="searchStatus === SEARCH_BEST_PRICE_COMPLETE"><b>Find Better Price </b></span>search.

                    <span *ngIf="searchStatus === SEARCH_BEST_PRICE_COMPLETE && listingCount > 1">The vehicle you are comparing against is shown first.</span>

                    <span *ngIf="searchStatus === SEARCH_BEST_PRICE_COMPLETE && listingCount === 1">Your choice was already the best priced vehicle within the search region!</span>

                    <span class="text-muted" *ngIf="resultCount > configService.resultsPerPage">There are {{resultCount | number}} total matching results. You are on page {{currentPage()}} of {{totalPages()}}.</span>

                    <span class="text-muted" *ngIf="resultCount === null">Loading total number of matching vehicles...</span>
                  </p>
                  <app-account-btns-l2 (loggedOut)="userIsLoggingOut()"></app-account-btns-l2>
                </div>
              </div>
            </div>

            <div class="mt-3"><app-favorite-vehicles-list></app-favorite-vehicles-list></div>

            <ng-container *ngTemplateOutlet="searchResultRow; context: {$implicit: searchResults}"></ng-container>

            <ng-container *ngIf="moreSearchResults.length > 0">
              <p class="alert alert-warning mt-4 mb-2 lead">The following <span *ngIf="moreSearchResults.length > 1">listings are</span><span *ngIf="moreSearchResults.length === 1">listing is</span> outside your search radius of {{searchRadius}} mile<span *ngIf="searchRadius > 1">s</span>.</p>
              <ng-container *ngTemplateOutlet="searchResultRow; context: {$implicit: moreSearchResults}"></ng-container>
            </ng-container>

            <div class="l2-srp-navi">
              <div class="l2-srp-navi-btns">
                <button *ngIf="+currentSearch['pageNum'] > 1" class="btn btn-lg btn-dark mt-3 mb-4 mr-3" (click)="prevPage()">⇦ Previous Page</button>
                <button *ngIf="resultCount > configService.resultsPerPage" class="btn btn-lg btn-dark mt-3 mb-4" (click)="nextPage()">Next Page ⇨</button>
              </div>
              <div class="l2-srp-navi-num">Page {{currentPage()}} / {{totalPages()}}</div>
            </div>
          </div>

          <div *ngIf="listingCount === 0">
            <p class="alert alert-warning mt-4 lead">There are no results that match your search.</p>
          </div>
        </ng-container>
      </ng-container>

      <app-footer></app-footer>
    </div>
  </div>
</div>

<ng-template #searchResultRow let-results>
  <div class="search-results row l2-search-rows">
    <ng-container *ngFor="let result of results; let i = index">
      <ng-container *ngIf="result.resultType === 'VehicleListingResult'">
        <app-search-result-1col *ngIf="viewCols === 1; else col2" [listing]="result.listing" class="search-result col-md-12 col-lg-6 col-xl-4 col-xxxl-3 l2-search-col l2-search-col-xl-1 " [id]="'listing' + result.listing.classifiedAdID" (findBetter)="loadBetterListings($event)" (checkAvailabilityView)="goToCheckAvailability($event)" (requestVehicleHistoryView)="goToVehicleHistoryRequest($event)" (compareView)="goToCompare($event)" (listingView)="goToListing($event)" (loansView)="goToLoans($event)"></app-search-result-1col>
        <ng-template #col2>
          <app-search-result-multicol *ngIf="viewCols === 2; else col3" [listing]="result.listing" class="search-result col-md-12 col-lg-6 l2-flex l2-search-multicols l2-search-col-xl-2" [id]="'listing' + result.listing.classifiedAdID" (findBetter)="loadBetterListings($event)" (checkAvailabilityView)="goToCheckAvailability($event)" (requestVehicleHistoryView)="goToVehicleHistoryRequest($event)" (compareView)="goToCompare($event)" (listingView)="goToListing($event)" (loansView)="goToLoans($event)"></app-search-result-multicol>
        </ng-template>
        <ng-template #col3>
          <app-search-result-multicol *ngIf="viewCols === 3;" [listing]="result.listing" class="search-result col-md-12 col-lg-6 col-xl-4 col-xxxl-3 l2-flex l2-search-multicols l2-search-col-xl-3" [id]="'listing' + result.listing.classifiedAdID" (findBetter)="loadBetterListings($event)" (checkAvailabilityView)="goToCheckAvailability($event)" (requestVehicleHistoryView)="goToVehicleHistoryRequest($event)" (compareView)="goToCompare($event)" (listingView)="goToListing($event)" (loansView)="goToLoans($event)"></app-search-result-multicol>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="configService.virtualCarSaleConfig?.registration">
        <app-car-sale-registration *ngIf="result.resultType == 'CarSaleRegistrationLeadResult' && viewCols === 1" class="search-result col-md-12 col-lg-6 col-xl-4 col-xxxl-3 l2-search-col l2-search-col-xl-1"></app-car-sale-registration>
        <app-car-sale-registration *ngIf="result.resultType == 'CarSaleRegistrationLeadResult' && viewCols === 2" class="search-result col-md-12 col-lg-6 l2-flex l2-search-multicols l2-search-col-xl-2"></app-car-sale-registration>
        <app-car-sale-registration *ngIf="result.resultType == 'CarSaleRegistrationLeadResult' && viewCols === 3" class="search-result col-md-12 col-lg-6 col-xl-4 col-xxxl-3 l2-flex l2-search-multicols l2-search-col-xl-3"></app-car-sale-registration>
      </ng-container>
    </ng-container>
  </div>
</ng-template>