<div class="search-result card" (contextmenu)="handleContextMenu($event)">
  <app-vehicle-top-bar [listing]="listing" (compareView)="compareView.emit($event)"></app-vehicle-top-bar>
  <app-dealer-tags-badge [dealerTags]="listing.dealerTags"></app-dealer-tags-badge>
  <app-custom-filter-badges [listing]="listing"></app-custom-filter-badges>
  <div *ngIf="(listing.price > 0 && getHasAFG() == true && (showAfgBadge() | async))">
    <a [routerLink]="['/loans', listing.classifiedAdID]" (mousedown)="handleAFGClick($event)" (click)="$event.stopPropagation()" #afgLink><app-afg-badge></app-afg-badge></a>
  </div>

  <app-image-slider [imageUrl]="listing.imageUrl" (mousedown)="handleListingClick($event)" (contextmenu)="handleContextMenu($event)" class="card-img-top go-to-listing"></app-image-slider>
  <app-new-listing-flag *ngIf="listing.daysOnMarket <= 7"></app-new-listing-flag>
  <app-price-rating [rating]="listing.priceRating"></app-price-rating>

  <div class="content card-body d-flex flex-column justify-content-between bg-white content-opt3">
    <!-- design option 2 -->
    <div class="descr go-to-listing d-flex flex-wrap justify-content-between">
      <h3 class="condition year make model trim card-title m-0 card-title-name-opt2 flex-60 d-flex align-items-center" (mousedown)="handleListingClick($event)" (contextmenu)="handleContextMenu($event)">
        <span>{{listing.year}} {{listing.make}} {{listing.model}} <span class="text-muted">{{listing.trim}}<span *ngIf="listing.isNew"> (New)</span></span></span>
      </h3>
      <div *ngIf="listing.price > 0" class="flex-fill flex-40 text-right">
        <ng-container [ngSwitch]="listing.price">
          <span class="price" *ngSwitchCase="0">Contact dealer for price</span>
          <span class="price" *ngSwitchDefault>{{listing.price | currency:'USD':digitsInfo:'1.0-0'}} </span>
        </ng-container>
        <div class="content text-wrap text-right" *ngIf="getHasAFG() != true">
          <div class="mt-1 mt-sm-0"><app-monthly-payment [listing]="listing"></app-monthly-payment></div>
        </div> 
      </div>
    </div> 

    <div class="listing-info d-flex flex-row justify-content-between flex-wrap">
       <div *ngIf="listing.price > 0" class="flex-fill mb-2">
        <div class="content-opt3 border-0" *ngIf="getHasAFG() == true">
          <div class="mt-1 mt-sm-0 border-0"><app-monthly-payment [listing]="listing"></app-monthly-payment></div>
          <div class="label-opt3" *ngIf="getHasAFG() == true">Conventional Loan</div>
        </div>
      </div>
      <div *ngIf="listing.price > 0 && getHasAFG() == true" class="flex-fill text-right">
        <div class="content-opt3 border-0">
          <div class="mt-1 mt-sm-0">
            <app-monthly-payment [listing]="listing" [conventionalLoan]="false"></app-monthly-payment>
          </div>
        </div>
        <div class="label-opt3">{{configService.balloonLoanProgramName}} Loan</div>
      </div>
    <div class="listing-info d-flex flex-row justify-content-between w-100">
      <div class="flex-fill">
        <div class="label">Odometer</div>
        <div class="content">
          <span *ngIf="listing.mileage !== null">{{listing.mileage | number}} mile<ng-container *ngIf="listing.mileage !== 1">s</ng-container></span>
          <span *ngIf="listing.mileage === null">Not provided</span>
        </div>
      </div>
      <div class="flex-fill text-center">
        <div class="label">Location</div>
        <div class="content">
          {{listing.location.city}}, {{listing.location.state}}<br>
        </div>
      </div>
      <div class="flex-fill text-right">
        <div class="label">Distance</div>
        <div class="content">
          {{listing.location.distanceMiles | number}} mile<ng-container *ngIf="listing.location.distanceMiles !== 1">s</ng-container> away
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-space-between listing-details flex-100">
      <button *ngIf="listing.freeHistoryReport" type="button" class="btn btn-dark btn-sm w-50 mr-3" (click)="carFaxService.pullCarFaxReport(listing.classifiedAdID)" ngbTooltip="A free history report may be available for this vehicle. Click to download.">Get Free {{listing.freeHistoryReport}}</button>
      <button *ngIf="!listing.freeHistoryReport && configService.vehicleHistoryRequestConfig && !listing.isNew" type="button" class="btn btn-dark btn-sm w-50 mr-3" (click)="requestVehicleHistory()" (auxclick)="requestVehicleHistory()" ngbTooltip="Check if a free vehicle history report is available for this vehicle.">Request {{configService.vehicleHistoryRequestConfig.reportName}}</button>
      <button *ngIf="!listing.freeHistoryReport && !configService.vehicleHistoryRequestConfig" type="button" class="btn btn-outline-primary btn-sm w-100" (mousedown)="handleCheckAvailabilityClick($event)" (click)="$event.stopPropagation()" #checkAvailabilityBtn>Check Availability</button>
      <button *ngIf="listing.freeHistoryReport || !listing.freeHistoryReport && configService.vehicleHistoryRequestConfig && !listing.isNew" type="button" class="btn btn-outline-primary btn-sm w-50" (mousedown)="handleCheckAvailabilityClick($event)" (click)="$event.stopPropagation()" #checkAvailabilityBtn>Check Availability</button>
    </div>
  </div>
  <div class="card-footer d-flex justify-content-between">
    <div ngbDropdown>
      <button ngbDropdownToggle class="btn btn-outline-dark find-better-price-btn" [ngbPopover]="fbpContent" triggers="mouseenter:mouseleave" popoverTitle="How It Works" [openDelay]="100" placement="right-bottom" container="body" [autoClose]="false">Find Better Price </button>
      <div ngbDropdownMenu>
        <button (click)="doFindBetter('state')" ngbDropdownItem>Search within entire state of {{listing.location.state}}</button>
        <button (click)="doFindBetter('regional')" ngbDropdownItem>Search within {{FIND_BETTER_PRICE_REGION}} miles of {{userSessionService.zipCode}}</button>
        <button (click)="doFindBetter('nationwide')" ngbDropdownItem>Search nationwide</button>
      </div>
    </div>
    <button type="button" class="btn btn-primary go-to-listing ml-1" type="submit" (mousedown)="handleListingClick($event)" (contextmenu)="handleContextMenu($event)">More Details & Pictures</button>
  </div>
</div>



<div
  [matMenuTriggerFor]="rightMenu"
  #rightMenuTrigger="matMenuTrigger"
  style="visibility: hidden; position: fixed;"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  class="wrapperRightMenu"
></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <!-- <ng-template matMenuContent let-item="item" *transloco="let t"> -->
   <div class="rightMenu"
   > 
    <button mat-menu-item (click)="viewListingToNewWindowFromMenu($event)">Open to a new tab</button>
    <button mat-menu-item (click)="copyLinkToListing($event)">Copy link</button>
   </div> 
  <!-- </ng-template> -->
</mat-menu>

<ng-template #fbpContent>
  <p>Did you know you may be able to save hundreds to thousands of dollars if you are willing to drive a few hours to find a better deal or have the vehicle shipped to your door by a professional vehicle delivery service?</p>
  <p class="mb-0">Use the <b>Find Better Price</b> tool to search the state, region, or entire nation for the best priced {{listing.make}} {{listing.model}} {{listing.trim}} listings with mileage and model years similar to or better than the listing above.</p>
</ng-template>
