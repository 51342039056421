<!-- Phase 2
<button>View Previous Searches</button>-->
<div class="text-center d-md-none search-btnicon-cont">
  <span class="search-btnicon">
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="#fff">
      <path d="M80-200v-60h400v60H80Zm0-210v-60h200v60H80Zm0-210v-60h200v60H80Zm758 420L678-360q-26 20-56 30t-62 10q-83 0-141.5-58.5T360-520q0-83 58.5-141.5T560-720q83 0 141.5 58.5T760-520q0 32-10 62t-30 56l160 160-42 42ZM559.765-380Q618-380 659-420.765q41-40.764 41-99Q700-578 659.235-619q-40.764-41-99-41Q502-660 461-619.235q-41 40.764-41 99Q420-462 460.765-421q40.764 41 99 41Z"/>
    </svg>
  </span>
  <button type="button" class="btn btn-dark m-3" (click)="isFormCollapsed = !isFormCollapsed">
    <span *ngIf="isFormCollapsed">Show</span>
    <span *ngIf="!isFormCollapsed">Hide</span> Search Options
  </button>
</div>

<form [formGroup]="searchForm" (submit)="doSearch()" [ngClass]="{'d-none d-md-block': isFormCollapsed}"
class="l2-search">
  <fieldset>
    <legend class="l2-form-title text-uppercase text-center">Search Options</legend>
    <div class="form-group d-flex justify-content-between mb-2">
      <!-- <button type="button" class="btn btn-primary flex-grow-1 mr-1" type="submit">Search</button> -->
      <button type="button" class="btn  btn-dark flex-grow-1 mr-1" type="submit">Search</button>
      <button type="button" class="btn l2-btn-light btn-outline-secondary" (click)="resetForm()" title="Reset" ngbTooltip="Reset search form">Reset</button>
    </div>
    <div class="form-group d-flex mb-2">
      <button *ngIf="userSessionService.isLoggedIn" type="button" class="btn btn-tiny btn-light btn-outline-secondary mr-1 flex-grow-1" (click)="saveSearch()">Save This Search</button>
      <button *ngIf="userSessionService.isLoggedIn" type="button" class="btn btn-tiny btn-light btn-outline-secondary" [routerLink]="['/savedSearches']" (click)="isFormCollapsed = !isFormCollapsed">View Saved Searches</button>
    </div>
    <div class="form-group" *ngIf="getVehicleTypes().length > 1">
      <label for="vehicleTypeInput">Vehicle Type</label>
      <select id="vehicleTypeInput" formControlName="vehicleType" class="custom-select">
        <option *ngFor="let vehicleType of getVehicleTypes()" [value]="vehicleType">{{vehicleType}}</option>
      </select>
    </div>
    <!-- <div class="form-group">
      <label for="conditionInput">Condition</label>
      <select id="conditionInput" formControlName="condition" class="custom-select">
        <option value="All">All</option>
        <option value="New">New</option>
        <option value="Used">Used</option>
      </select>
    </div> -->
    <!-- TOGGLE for the CONDITION -->
    <div class="condition-form-group">
      <label for="conditionInput">Condition</label>
      <div class="condition-toggle-group" >
        <label  class="condition-toggle-option con-all" >
          <input  type="radio" id="allOption" formControlName="condition" [value]="'All'" checked tabindex="0">
          <div>All</div>
        </label>
        <label  class="condition-toggle-option expand con-new">
          <input  type="radio" id="newOption" formControlName="condition" [value]="'New'" tabindex="1">
          <div>New</div>
        </label>
        <label  class="condition-toggle-option expand con-used">
          <input  type="radio" id="usedOption" formControlName="condition" [value]="'Used'" tabindex="2">
          <div>Used</div>
        </label>
      </div>
    </div>
    <!-- end TOGGLE for the CONDITION -->
  	<div class="form-group">
      <div class="accordion-container">
        <div class="accordion-header" [class.open]="isAccordionOpen('makes')" (click)="toggleAccordion('makes')">
          <div class="header-content">
            <label>Make</label>
            <span class="selected-count" *ngIf="getSelectedMakes().length > 0">
              ({{getSelectedMakes().length}} selected)
              <span class="clear-selection" (click)="clearSelection('makes'); $event.stopPropagation()">×</span>
            </span>
          </div>
          <svg class="chevron" viewBox="0 0 24 24" width="24" height="24">
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
          </svg>
        </div>
        <div class="accordion-content" [class.open]="isAccordionOpen('makes')">
          <div class="search-box" *ngIf="isAccordionOpen('makes')">
            <input type="text" 
                   [formControl]="makeSearchControl"
                   placeholder="Search makes..."
                   class="form-control">
          </div>
          <div class="option-list">
            <div class="option" *ngIf="!makeSearchControl.value" 
                 (click)="toggleMakeSelection('All')" 
                 [class.selected]="isMakeSelected('All')">
              All
            </div>
            <div class="option" *ngFor="let make of getFilteredMakes()" 
                 (click)="toggleMakeSelection(make)" 
                 [class.selected]="isMakeSelected(make)">
              {{make}}
            </div>
          </div>
          <div class="scroll-indicator"></div>
        </div>
        <div class="selected-items" *ngIf="getSelectedMakes().length > 0" [@fadeInOut]>
          <div class="selected-item" *ngFor="let make of getSelectedMakes()">
            {{make}}
            <span class="remove-item" (click)="toggleMakeSelection(make); $event.stopPropagation()">×</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="accordion-container">
        <div class="accordion-header" [class.open]="isAccordionOpen('models')" (click)="toggleAccordion('models')">
          <div class="header-content">
            <label>Model</label>
            <span class="selected-count" *ngIf="getSelectedModels().length > 0">
              ({{getSelectedModels().length}} selected)
              <span class="clear-selection" (click)="clearSelection('models'); $event.stopPropagation()">×</span>
            </span>
          </div>
          <svg class="chevron" viewBox="0 0 24 24" width="24" height="24">
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
          </svg>
        </div>
        <div class="accordion-content" [class.open]="isAccordionOpen('models')">
          <div class="search-box" *ngIf="isAccordionOpen('models')">
            <input type="text" 
                   [formControl]="modelSearchControl"
                   placeholder="Search models..."
                   class="form-control">
          </div>
          <div class="option-list">
            <div class="option" *ngIf="!modelSearchControl.value"
                 (click)="toggleModelSelection('All')" 
                 [class.selected]="isModelSelected('All')">
              All
            </div>
            <div class="option" *ngFor="let model of getFilteredModels()" 
                 (click)="toggleModelSelection(model)" 
                 [class.selected]="isModelSelected(model)"
                 (mouseenter)="showFullImage = model" 
                 (mouseleave)="showFullImage = null">
              <div class="model-option-content">
                <div class="thumbnail-container">
                  <img [src]="getThumbnailUrl(model)" alt="Vehicle" class="model-thumbnail">
                  <div *ngIf="showFullImage === model" class="full-image-container" [ngStyle]="getFullImageStyle()">
                    <img [src]="getThumbnailUrl(model)" alt="Vehicle" class="full-image">
                  </div>
                </div>
                <span>{{model}}</span>
              </div>
            </div>
          </div>
          <div class="scroll-indicator"></div>
        </div>
        <div class="selected-items" *ngIf="getSelectedModels().length > 0" [@fadeInOut]>
          <div class="selected-item" *ngFor="let model of getSelectedModels()">
            {{model}}
            <span class="remove-item" (click)="toggleModelSelection(model); $event.stopPropagation()">×</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="accordion-header" [class.open]="isAccordionOpen('bodyStyles')" (click)="toggleAccordion('bodyStyles')">
        <div class="header-content">
          <label>Body Style</label>
          <span class="selected-count" *ngIf="getSelectedBodyStyles().length > 0">
            ({{getSelectedBodyStyles().length}} selected)
            <span class="clear-selection" (click)="clearSelection('bodyStyles'); $event.stopPropagation()">×</span>
          </span>
        </div>
        <svg class="chevron" viewBox="0 0 24 24" width="24" height="24">
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
        </svg>
      </div>
      <div class="accordion-content" [class.open]="isAccordionOpen('bodyStyles')">
        <div class="option-list">
          <div class="option" (click)="toggleBodyStyleSelection('All')" [class.selected]="isBodyStyleSelected('All')">
            All
          </div>
          <div class="option" *ngFor="let bodyStyle of getMatchingBodyStyles()" 
               (click)="toggleBodyStyleSelection(bodyStyle)" 
               [class.selected]="isBodyStyleSelected(bodyStyle)">
            {{bodyStyle}}
          </div>
        </div>
        <div class="scroll-indicator"></div>
      </div>
      <div class="selected-items" *ngIf="getSelectedBodyStyles().length > 0" [@fadeInOut]>
        <div class="selected-item" *ngFor="let bodyStyle of getSelectedBodyStyles()">
          {{bodyStyle}}
          <span class="remove-item" (click)="toggleBodyStyleSelection(bodyStyle); $event.stopPropagation()">×</span>
        </div>
      </div>
    </div>
    <div class="form-group mb-2">
      <label for="keywords">Trim or Other Keywords</label>
      <input id="keywords" formControlName="keywords" class="form-control" placeholder="example: LX, 2.2L">
      <p class="l2-text-muted small">Use commas to separate keywords</p>
    </div>
    <label for="yearMinInput">Year</label>
    <div class="form-row">
      <div class="form-group col">
        <select id="yearMinInput" formControlName="minYear" class="custom-select">
          <option value="0">any year</option>
          <option *ngFor="let year of searchYears" [value]="year">from {{year}}</option>
        </select>
      </div>
      <div class="form-group col">
        <select id="yearMaxInput" formControlName="maxYear" class="custom-select">
          <option value="0">to current</option>
          <option *ngFor="let year of searchYears" [value]="year">to {{year}}</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label for="maxMileageInput">Maximum Mileage</label>
      <select id="maxMileageInput" formControlName="maxMileage" class="custom-select">
        <option value="0">Max</option>
        <option *ngFor="let mileage of searchMaxMileages" [value]="mileage">up to {{mileage | number:'1.0-0'}}</option>
      </select>
    </div>
    <div class="p-3 monthly-price">
      <div class="priceOrMonthlyPayment">
        <label *ngIf="this.searchForm.value.vehicleType === 'Auto'" class="l2-price-monthly">Price or Monthly Payment</label>
        <div *ngIf="this.searchForm.value.vehicleType === 'Auto'" class="small tiny-label">select either option</div>
        <div [ngClass]="{'d-none': this.searchForm.value.vehicleType !== 'Auto'}" class="btn-group" role="group">
          <input class="btn-check" type="radio" value="price" formControlName="priceOrMonthlyPayment" autocomplete="off" id="priceOrMonthlyPayment_price">
          <label class="btn btn-outline-secondary" for="priceOrMonthlyPayment_price">Price</label>

          <input class="btn-check" type="radio" value="payment" formControlName="priceOrMonthlyPayment" autocomplete="off" id="priceOrMonthlyPayment_payment">
          <label class="btn btn-outline-secondary" for="priceOrMonthlyPayment_payment">Monthly Payment</label>
        </div>
      </div>
      <div *ngIf="searchForm.value.priceOrMonthlyPayment === 'price'">
        <label *ngIf="this.searchForm.value.vehicleType !== 'Auto'" for="priceRangeMinInput">Price</label>
        <div class="form-row">
          <div class="form-group col">
            <select id="priceRangeMinInput" formControlName="minPrice" class="custom-select">
              <option value="0">no min</option>
              <option *ngFor="let price of searchPrices" [value]="price">from {{price | currency:'USD':digitsInfo:'1.0-0'}}</option>
            </select>
          </div>
          <div class="form-group col">    
            <select id="priceRangeMaxInput" formControlName="maxPrice" class="custom-select">
              <option value="0">no max</option>
              <option *ngFor="let price of searchPrices" [value]="price">to {{price | currency:'USD':digitsInfo:'1.0-0'}}</option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="searchForm.value.priceOrMonthlyPayment === 'payment'">
        <div class="form-row">
          <div class="form-group col mb-2">
            <select id="paymentRangeMinInput" class="custom-select" formControlName="minPayment">
              <option value="0">no min</option>
              <option value="50">from $50</option>
              <option value="100">from $100</option>
              <option value="125">from $125</option>
              <option value="150">from $150</option>
              <option value="175">from $175</option>
              <option value="200">from $200</option>
              <option value="250">from $250</option>
              <option value="300">from $300</option>
              <option value="350">from $350</option>
              <option value="400">from $400</option>
              <option value="450">from $450</option>
              <option value="500">from $500</option>
              <option value="600">from $600</option>
              <option value="700">from $700</option>
              <option value="800">from $800</option>
              <option value="900">from $900</option>
              <option value="1000">from $1000</option>
              <option value="1250">from $1250</option>
              <option value="1500">from $1500</option>
              <option value="1750">from $1750</option>
              <option value="2000">from $2000</option>
              <option value="2500">from $2500</option>
              <option value="3000">from $3000</option>
            </select>
          </div>
          <div class="form-group col mb-2">    
            <select id="paymentRangeMaxInput" class="custom-select" formControlName="maxPayment">
              <option value="0">no max</option>
              <option value="50">to $50</option>
              <option value="100">to $100</option>
              <option value="125">to $125</option>
              <option value="150">to $150</option>
              <option value="175">to $175</option>
              <option value="200">to $200</option>
              <option value="250">to $250</option>
              <option value="300">to $300</option>
              <option value="350">to $350</option>
              <option value="400">to $400</option>
              <option value="450">to $450</option>
              <option value="500">to $500</option>
              <option value="600">to $600</option>
              <option value="700">to $700</option>
              <option value="800">to $800</option>
              <option value="900">to $900</option>
              <option value="1000">to $1000</option>
              <option value="1250">to $1250</option>
              <option value="1500">to $1500</option>
              <option value="1750">to $1750</option>
              <option value="2000">to $2000</option>
              <option value="2500">to $2500</option>
              <option value="3000">to $3000</option>
            </select>
          </div>
        </div>
        <div class="conventionalOrBalloonLoan" *ngIf="getHasAFG() == true">
          <div class="btn-group">
            <input class="btn-check" type="radio" value="conventional" formControlName="conventionalOrBalloonLoan" autocomplete="off" id="conventionalOrBalloonLoan_conventional">
            <label class="btn btn-outline-secondary" ngbTooltip="With this loan type, the vehicle is paid off fully" for="conventionalOrBalloonLoan_conventional">
              Conventional
            </label>

            <input class="btn-check" type="radio" value="balloon" formControlName="conventionalOrBalloonLoan" autocomplete="off" id="conventionalOrBalloonLoan_balloon">
            <label class="btn btn-outline-secondary" ngbTooltip="With this loan type, the vehicle is not fully paid off but monthly payments are lower" for="conventionalOrBalloonLoan_balloon">
               {{afg.programName}}
            </label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col">
            <p class="mt-0 small">Your monthly payment will vary depending on your downpayment, loan term, and the interest rate for which you qualify. For the most accurate results: do a search, click on the monthly payment amount on any listing, tweak those settings, then execute the search again to apply the updated loan settings.</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-7">
          <label for="searchRadiusInput">Search Radius</label>
          <select id="searchRadiusInput" formControlName="radius" class="custom-select">
            <option *ngFor="let radius of searchRadiusOptions" [value]="radius">{{radius}} miles</option>
            <option value="0">Unlimited</option>
          </select>
        </div>
        <div class="form-group col-5">
          <label for="zip">Zip Code</label>
          <input type="zip" class="form-control" formControlName="zip" id="zip" placeholder="Zip">
        </div>
      </div>
      <div class="form-row" *ngFor="let customFilter of customFilters; let i = index">
        <div class="form-group custom-control custom-switch" *ngIf="getCustomFilterFormControl(i) as customFilterControl">
          <input class="custom-control-input" type="checkbox" id="customFilter-{{stringToId(customFilter.name)}}" [formControl]="customFilterControl">
          <label for="customFilter-{{stringToId(customFilter.name)}}" class="custom-control-label" [ngbTooltip]="customFilter.tooltip">{{customFilter.formLabel}}</label>
        </div>
      </div>
      <div class="form-row" *ngIf="getHasAFG() == true">
        <div class="form-group col-12">
          <label for="loanTypeEligibilityInput">Loan Type Eligibility</label>
          <select id="loanTypeEligibilityInput" formControlName="loanTypeEligibility" class="custom-select">
            <option value="All">All Vehicles</option>
            <option value="BalloonEligibleFilter">Eligible for {{afg.programName}} Loan</option>
            <!-- Note: the value of this field ^ must match the name of the corresponding case object on the scala side -->
          </select>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="sortByInput">Sort By</label>
      <select id="sortByInput" formControlName="sortBy" class="custom-select">
        <option value="Default">Recommended (Best First)</option>
        <option value="PriceL2H">Price (Low to High)</option>
        <option value="PriceH2L">Price (High to Low)</option>
        <option value="MileageL2H">Mileage (Low to High)</option>
        <option value="MileageH2L">Mileage (High to Low)</option>
        <option value="DistanceL2H">Distance (Close to Far)</option>
        <option value="DistanceH2L">Distance (Far to Close)</option>
        <option value="WhenListedL2H">Days on Market (Newest First)</option>
        <option value="WhenListedH2L">Days on Market (Oldest First)</option>
      </select>
    </div>
    <div class="form-group d-flex justify-content-between l2-search-btns">
      <button type="button" class="btn btn-dark mr-3" type="submit">Search</button>
      <button type="button" class="btn l2-btn-light" (click)="resetForm()" title="Reset" ngbTooltip="Reset search">Reset</button>
    </div>
  </fieldset>

  <fieldset>
    <legend class="l2-form-title text-uppercase text-center">Advanced Options</legend>

    <!-- <button type="button" class="btn btn-outline-primary mb-2" (click)="isAdvancedCollapsed = !isAdvancedCollapsed" [attr.aria-expanded]="!isAdvancedCollapsed" aria-controls="advancedSearch"><span *ngIf="isAdvancedCollapsed">Show More Search Options</span><span *ngIf="!isAdvancedCollapsed">Hide More Search Options</span></button> -->
    <button type="button" class="btn l2-btn-black btn-outline-primary mb-2" (click)="isAdvancedCollapsed = !isAdvancedCollapsed" [attr.aria-expanded]="!isAdvancedCollapsed" aria-controls="advancedSearch"><span *ngIf="isAdvancedCollapsed">Show More Search Options</span><span *ngIf="!isAdvancedCollapsed">Hide More Search Options</span></button>

    <div id="advancedSearch" [ngbCollapse]="isAdvancedCollapsed">
      <!-- Phase 2
      <div class="form-group">
        <label for="minimumSafetyInput">Minimum Safety Rating</label>
        <select id="minimumSafetyInput">
          <option value="1">1 star</option>
          <option value="2">2 star</option>
          <option value="3">3 star</option>
          <option value="4">4 star</option>
          <option value="5">5 star</option>
        </select>
      </div>
      <div class="form-group">
        <label for="mpgInput">Miles per Gallon</label>
        <input id="mpgInput">
      </div>-->
      <div class="form-group">
        <label for="exteriorColors">Exterior Color <div class="l2-text-muted small">you can select multiple / scroll to see more</div></label>
        <select id="exteriorColors" formControlName="exteriorColors" class="custom-select" multiple>
          <option value="All">All</option>
          <option value="Black">Black</option>
          <option value="Silver">Silver</option>
          <option value="White">White</option>
          <option value="Gray">Gray</option>
          <option value="Brown">Brown</option>
          <option value="Green">Green</option>
          <option value="Beige">Beige</option>
          <option value="Yellow">Yellow</option>
          <option value="Blue">Blue</option>
          <option value="Red">Red</option>
          <option value="Gold">Gold</option>
          <option value="Orange">Orange</option>
          <option value="Purple">Purple</option>
          <option value="Pink">Pink</option>
        </select>
      </div>
      <div class="form-group">
        <label for="interiorColors">Interior Color <div class="l2-text-muted small">you can select multiple / scroll to see more</div></label>
        <select id="interiorColors" formControlName="interiorColors" class="custom-select" multiple>
          <option value="All">All</option>
          <option value="Black">Black</option>
          <option value="Silver">Silver</option>
          <option value="White">White</option>
          <option value="Gray">Gray</option>
          <option value="Brown">Brown</option>
          <option value="Green">Green</option>
          <option value="Beige">Beige</option>
          <option value="Yellow">Yellow</option>
          <option value="Blue">Blue</option>
          <option value="Red">Red</option>
          <option value="Gold">Gold</option>
          <option value="Orange">Orange</option>
          <option value="Purple">Purple</option>
          <option value="Pink">Pink</option>
        </select>
      </div>
      <div class="form-row">
        <div class="form-group col-6">
          <label for="cityMpg">City MPG (≥)</label>
          <input class="form-control" formControlName="cityMpg" id="cityMpg">
        </div>
        <div class="form-group col-6">
          <label for="hwyMpg">Hwy MPG (≥)</label>
          <input class="form-control" formControlName="hwyMpg" id="hwyMpg">
        </div>
      </div>
      <div class="form-group">
        <label for="transmission">Transmission</label>
        <select id="transmission" formControlName="transmission" class="custom-select">
          <option value="All">All</option>
          <option value="Manual">Manual</option>
          <option value="Other">Automatic / Other</option>
        </select>
      </div>
      <div class="form-row">
        <div class="form-group col-6">
          <label for="driveTrain">Drivetrain</label>
          <select id="driveTrain" formControlName="driveTrain" class="custom-select">
            <option value="All">All</option>
            <option value="FWD">FWD</option>
            <option value="RWD">RWD</option>
            <option value="4WD">4WD / AWD</option>
          </select>
        </div>
        <div class="form-group col-6">
          <label for="daysOnMarket">Days for Sale (≤)</label>
          <input id="daysOnMarket" formControlName="daysOnMarket" class="form-control" placeholder="All">
        </div>
      </div>
      <div class="form-group">
        <label for="cargoCapacity">Cargo Capacity in Ft.<sup>3</sup> (≥)</label>
        <input id="cargoCapacity" formControlName="cargoCapacity" class="form-control">
      </div>
      <div class="form-group">
        <label for="cylinders">Cylinders <div class="l2-text-muted small">you can select multiple / scroll to see more</div></label>
        <select id="cylinders" formControlName="cylinders" class="custom-select" multiple>
          <option value="All">All</option>
          <option value="4">4</option>
          <option value="6">6</option>
          <option value="8">8</option>
          <option value="10">10</option>
          <option value="12">12</option>
        </select>
      </div>
      <div class="form-group">
        <label for="doorCount">Door Count</label>
        <select id="doorCount" formControlName="doorCount" class="custom-select">
          <option>All</option>
          <option value="2">2-door</option>
          <option value="4">4-door</option>
          <option disabled>──────────</option>
          <option value="1">1-door</option>
          <option value="3">3-door</option>
          <option value="5">5-door</option>
          <option value="6">6-door</option>
        </select>
      </div>
      <div class="form-group">
        <label for="fuelType">Fuel Type</label>
        <select id="fuelType" formControlName="fuelType" class="custom-select">
          <option value="All">All</option>
          <option value="Gas">Gas</option>
          <option value="Diesel">Diesel</option>
          <option value="Hybrid">Hybrid</option>
          <option value="Electric">Electric</option>
        </select>
      </div>
      <div class="form-group" *ngIf="isSalvageSearchAllowed">
        <label for="salvageInput">Salvage Vehicles</label>
        <select id="salvageInput" formControlName="salvage" class="custom-select">
          <option value="All">Both Salvage and Non-Salvage</option>
          <option value="No">Exclude Salvage Vehicles</option>
          <option value="Only">Only Salvage Vehicles</option>
        </select>
      </div>
      <!-- Phase 2
      <div class="form-group">
        <label for="featuresInput">Features</label>
        <select id="featuresInput">
          <option>Select Features</option>
          <option>GPS Navigation</option>
          <option>Backup Cam</option>
          <option>Heated Seats</option>
        </select>
      </div>
      -->
      <div class="form-group d-flex justify-content-between l2-search-btns">
        <button type="button" class="btn btn-dark mr-3" type="submit">Search</button>
        <button type="button" class="btn l2-btn-light btn-outline-secondary" (click)="resetForm()" title="Reset" ngbTooltip="Reset search form">Reset</button>
      </div>
    </div>
  </fieldset>
</form>
