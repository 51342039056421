import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { MatMenuModule, MatMenuTrigger} from '@angular/material/menu';

import { AFGService } from "@services/afg.service";
import { CarFaxService } from  '@services/car-fax.service';
import { ConfigService } from '@services/config.service';
import { ListingClick } from '@models/listing-click';
import { FindBetterListingsSearch } from '@services/search.service';
import { UserSessionService } from '@services/user-session.service';
import { VehicleListing } from '@models/vehicle-listing';
import { VTDButtonService } from '@services/vtd-button.service';
import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';
import { NewListingFlagComponent } from '../new-listing-flag/new-listing-flag.component';
import { ClipboardService } from '@services/clipboard.service';
import { environment } from '@environments/environment';

import { Router } from '@angular/router';

@Component({
  imports: [CommonModule, NewListingFlagComponent, RouterModule, VehicleSearchSharedModule, MatMenuModule ],
  selector: 'app-search-result-1col',
  standalone: true,
  templateUrl: './search-result-1col.component.html',
  styleUrls: ['./search-result-1col.component.sass']
})
export class SearchResultComponent1col implements OnInit {

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe

  @Input() listing!: VehicleListing;
  @Output() checkAvailabilityView: EventEmitter<string> = new EventEmitter<string>();
  @Output() requestVehicleHistoryView: EventEmitter<string> = new EventEmitter<string>();
  @Output() compareView: EventEmitter<string> = new EventEmitter<string>();
  @Output() findBetter: EventEmitter<FindBetterListingsSearch> = new EventEmitter<FindBetterListingsSearch>();
  @Output() listingView: EventEmitter<ListingClick> = new EventEmitter<ListingClick>();
  @Output() loansView: EventEmitter<ListingClick> = new EventEmitter<ListingClick>();

  // Changes to this value need to be coordinated with CarBuyingServiceImpl.FIND_BETTER_PRICE_REGION
  readonly FIND_BETTER_PRICE_REGION = 500;

  @ViewChild('rightMenuTrigger') rightMenuTrigger!: MatMenuTrigger;
  @ViewChild('afgLink') afgLink!: ElementRef;
  @ViewChild('checkAvailabilityBtn') checkAvailabilityBtn!: ElementRef;
  menuTopLeftPosition = { x: '0', y: '0' };
  isAFGContextMenu = false;
  isCheckAvailabilityContextMenu = false;

  constructor(
    public afg: AFGService,
    public carFaxService: CarFaxService,
    public configService: ConfigService,
    public userSessionService: UserSessionService,

    private vtd: VTDButtonService,
    private clipboardService: ClipboardService,
    private router: Router,

  ) { }

  ngOnInit() {
  }

  checkAvailability() {
    this.checkAvailabilityView.emit(this.listing.classifiedAdID);
  }

  doFindBetter(locationRestriction: string) {
    this.findBetter.emit({
      selectedVehicle: this.listing,
      locationRestriction: locationRestriction,
    });
  }

  requestVehicleHistory() {
    this.requestVehicleHistoryView.emit(this.listing.classifiedAdID);
  }

  handleContextMenu(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
  
    // Check if the right-click occurred on or within the AFG link or Check Availability button
    this.isAFGContextMenu = this.afgLink?.nativeElement?.contains(event.target as Node) || false;
    this.isCheckAvailabilityContextMenu = this.checkAvailabilityBtn?.nativeElement?.contains(event.target as Node) || false;
  
    this.rightMenuTrigger.openMenu();
  }

  handleListingClick(event: MouseEvent) {
    if (event.button === 2) {
      // Do nothing for right-clicks on mousedown
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.viewListing(event);
  }

  handleAFGClick(event: MouseEvent) {
    if (event.button === 0 || event.button === 1) {
      event.stopPropagation();
      this.viewLoans(event);
    }
  }

  handleCheckAvailabilityClick(event: MouseEvent) {
    if (event.button === 0 || event.button === 1) {
      event.stopPropagation();
      event.preventDefault();
      const openInNewWindow = event.button === 1 || event.ctrlKey || event.shiftKey || event.metaKey;
      if (openInNewWindow) {
        const url = this.router.createUrlTree(['/checkAvailability', this.listing.classifiedAdID]).toString();
        window.open(this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + url, '_blank');
      } else {
        this.checkAvailabilityView.emit(this.listing.classifiedAdID);
      }
    }
  }

  viewListing(event: MouseEvent) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    this.listingView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: openInNewWindow});
  }

  viewListingToNewWindowFromMenu(event: MouseEvent) {
    let url: string;
    if (this.isAFGContextMenu) {
      url = this.router.createUrlTree(['/loans', this.listing.classifiedAdID]).toString();
    } else if (this.isCheckAvailabilityContextMenu) {
      url = this.router.createUrlTree(['/checkAvailability', this.listing.classifiedAdID]).toString();
    } else {
      url = this.router.createUrlTree(['/listing', this.listing.classifiedAdID]).toString();
    }
    window.open(this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + url, '_blank');
  }

  copyLinkToListing($event: MouseEvent) {
    let routerCommands: string[];
    let linkType: string;

    if (this.isAFGContextMenu) {
      routerCommands = ['/loans', this.listing.classifiedAdID];
      linkType = 'Loans for ';
    } else if (this.isCheckAvailabilityContextMenu) {
      routerCommands = ['/checkAvailability', this.listing.classifiedAdID];
      linkType = 'Check Availability for ';
    } else {
      routerCommands = ['/listing', this.listing.classifiedAdID];
      linkType = '';
    }

    const url = this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + this.router.createUrlTree(routerCommands).toString();
    const successMsg = 'Link to <strong>' + linkType + this.listing.make + ' ' + this.listing.model + '</strong> copied';
    this.clipboardService.copy(url, successMsg, $event);
  }

  viewLoans(event: MouseEvent) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    this.loansView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: openInNewWindow});
  }

  getHasAFG() {
    if (this.configService.balloonLoanProgramName != null) {
      return true;
    } else {
      return false;
    }
  }
  
  showAfgBadge(): Observable<boolean> {
    return this.afg.isAfgEligible(this.listing);
  }
  
  // details
  playVtd() {
    if (this.listing.moreDetail && this.listing.moreDetail.vtdUrl) {
      this.vtd.play(this.listing.moreDetail.vtdUrl, this.listing.classifiedAdID);
    }
  }

}
